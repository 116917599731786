module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"G current","short_name":"G current","description":"G current は現代美術のギャラリーと企業、そして人をつなぐプラットフォームです。企業と現代美術の接点を増やす仕組みをつくり、国内現代美術の国際発信力、競争力の向上を目的としています。","start_url":"/","display":"standalone","background_color":"#fff","theme_color":"#fff","icon":"./static/favicon.png","crossOrigin":"anonymous","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"6484b5520a39db5fd43affb7b5c92de4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7SCENM3W97"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
